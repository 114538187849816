import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "@emotion/react"
import styled from "@emotion/styled"
import { device } from "../device"

import Header from "./header"
import "./layout.css"
import theme from "../theme"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Container page={page}>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Miki Mochizuka`}
          page={page}
        />
        <Main page={page}>{children}</Main>
      </Container>
    </ThemeProvider>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: ${props => (props.page === "Home" ? 0 : 40)}px;

  @media ${device.mobile} {
    flex-direction: column;
    padding: ${props => (props.page === "Home" ? 0 : 24)}px;
  }
`

const Main = styled.main`
  flex-flow: 1;
  width: ${props => (props.page === "Home" ? 100 : 80)}%;
  margin-left: auto;
  max-height: ${props =>
    props.page == "Paintings" ? "calc(100vh - 80px)" : "auto"};
  overflow: hidden;
  /* background-color: yellow; */
  @media ${device.mobile} {
    width: 100%;
    margin-top: ${props => (props.page === "Home" ? 0 : 56)}px;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
