const theme = {
  fontFamily: {
    roboto: `'Roboto', sans-serif`,
    lora: `'Lora', serif`,
  },
  color: {
    base: "#232323",
  },
}

export default theme
