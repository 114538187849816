import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { device } from "../device"
import { StaticImage } from "gatsby-plugin-image"

import Humberger from "./huberger"

const Header = ({ siteTitle }) => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <StyledHeader isOpen={toggleMenu}>
      <Logo>
        <Link to="/">{siteTitle}</Link>
      </Logo>
      <NavigationWrapper isOpen={toggleMenu}>
        <StyledNavigation>
          <ul>
            <li>
              <Link to="/paintings">Paintings</Link>
            </li>
            <li>
              <Link
                css={css`
                  pointer-events: none;
                  cursor: default;
                  text-decoration: line-through !important;
                  opacity: 0.5;
                `}
                to="/workshop"
              >
                Workshop
              </Link>
            </li>
            <li>
              <Link to="/movies">Movies</Link>
            </li>
            <li>
              <Link to="/news">News</Link>
            </li>
            <li>
              <Link to="/marchandise">Marchandise</Link>
            </li>
            <li>
              <Link to="/cv">CV / Contact</Link>
            </li>
          </ul>
        </StyledNavigation>
        <HeaderBottom>
          <SNSLinks>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.instagram.com/mikimochizuka/?r=nametag"
                >
                  <StaticImage
                    src="../images/instagram-logo.svg"
                    alt="Instagram icon"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.facebook.com/miki.mochizuka"
                >
                  <StaticImage
                    src="../images/facebook.svg"
                    alt="facebook icon"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.youtube.com/channel/UCQJDm10tg_Brh0z-8ieWkQw"
                >
                  <StaticImage src="../images/youtube.png" alt="Youtube icon" />
                </a>
              </li>
            </ul>
          </SNSLinks>
          <Copy>
            &copy; {new Date().getFullYear()} Miki Mochizuka {" / "} design by{" "}
            <a
              href="https://okysk.com"
              target="_blank"
              rel="noreferrer noopener"
              css={css`
                text-decoration: none;
                color: rgba(0, 0, 0, 0.7);
                opacity: 0.8;
              `}
            >
              Yusuke Oike
            </a>
          </Copy>
        </HeaderBottom>
      </NavigationWrapper>
      <HumbergerContainer onClick={() => setToggleMenu(!toggleMenu)}>
        <Humberger size="24" isOpen={toggleMenu} />
      </HumbergerContainer>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
  justify-content: space-between;

  @media ${device.mobile} {
    background-color: white;
    padding: 24px;
    flex-basis: auto !important;
    border-top: 1px solid #232323;
    li {
      line-height: 2.4;
    }
    opacity: ${props => (props.isOpen ? "1" : "0")};
    transform: translateY(${props => (props.isOpen ? "0" : "-100")}%);
    transition: all 0.7s ease-in-out;
    display: ${props => (props.isOpen ? "flex" : "none")};
  }
`

const SNSLinks = styled.nav`
  ul {
    display: flex;
    align-items: center;
    li {
      display: block;
      width: 20px;
      img {
        display: block;
        width: 100%;
      }
      margin-right: 8px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`

const Copy = styled.p`
  font-size: 0.85rem;
  font-family: ${props => props.theme.fontFamily.roboto};
`

const HumbergerContainer = styled.div`
  display: none;
  position: fixed;
  top: 24px;
  right: 24px;

  @media ${device.mobile} {
    display: block;
    z-index: 999999;
  }
`

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 100vh;
  padding: 40px;
  z-index: 6000;
  background-color: transparent;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    background-color: ${props => (props.isOpen ? "white" : "transparent")};
    height: auto !important;
    z-index: 9999;
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
`

const StyledNavigation = styled.nav`
  margin: 0;
  font-size: 14px;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${props => props.theme.fontFamily.roboto};
  ul {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`

const Logo = styled.h1`
  font-family: ${props => props.theme.fontFamily.lora};
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  letter-spacing: 0.3px;
  z-index: 8000;
  a {
    color: ${props => props.theme.color.base};
    text-decoration: none;
  }

  @media ${device.mobile} {
    padding: 24px;
    background-color: transparent;
  }
`

const HeaderBottom = styled.div``

export default Header
